@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap);
@font-face {
  font-family: 'BestermindRegular';
  src: url(/static/media/BestermindRegular.58f07bc1.ttf); 
  src: url(/static/media/BestermindRegular.58f07bc1.ttf) format('embedded-opentype'), 
       url(/static/media/BestermindRegular.58f07bc1.ttf)  format('truetype'), 
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}


/* scrollBar */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #555;
}
::-webkit-scrollbar-thumb {
    background: #555;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

img{
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
            user-select: none; 

}


@media screen and (max-width: 400px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    body {
      overflow-x: hidden;
    }
}

.navbar {
    height: 6.5rem;
    position: absolute;
    background-color: transparent;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbar--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
    margin-top: 1rem;
}

.navbar--container > h1 {
    font-family: "BestermindRegular";
    color: var(--primary-2);
    font-size: 2.5rem;
    -webkit-user-select: none;
            user-select: none;
}

.navLink--container {
    margin-top: 5rem;
}

.drawer > .MuiBackdrop-root {
    background: rgba(33, 33, 33, 0.15) !important;
    -webkit-backdrop-filter: blur(20px) !important;
            backdrop-filter: blur(20px) !important;
}

@media (max-width: 1100px) {
    .navbar--container {
        padding: 0 2rem;
    }
    .navbar--container > h1 {
        font-size: 2.2rem;
    }
    .nav-menu {
        font-size: 2.2rem;
        margin-top: -1.5rem;
    }
}

@media (max-width: 800px) {
    .navLink--container {
        margin-top: 3rem;
    }
}

@media (max-width: 600px) {
    .nav-menu {
        color: var(--primary-2);
    }
    .navbar--container > h1 {
        font-size: 2rem;
    }
    .nav-menu:hover {
        color: var(--primary-2);
    }
    .MuiDrawer-paper {
        border-radius: 0 !important;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .navbar {
        height: 5rem;
    }
    .navbar--container {
        padding: 0 1rem;
        margin-top: 0rem;
    }
    .navbar--container > h1 {
        font-size: 1.5rem;
    }
}

.footer {
    font-family: 'var(--primaryFont)', sans-serif;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer p {
    font-weight: 500;
    font-family: var(--primaryFont);
    font-size: 18px;
}

.footer p span {
    font-size: 24px;
}
.landing {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing--container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
}

.landing--img {
    --img-size: 400px;
    position: absolute;
    left: 35%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: var(--img-size);
    height: var(--img-size);
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s;
}

.landing--container-left {
    flex: 35% 1;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.lcl--content {
    margin: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.landing--social {
    font-size: 35px;
    margin: 0 1rem;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.landing--social:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.landing--container-right {
    flex: 65% 1;
    height: 100%;
    background-color: #eaeaea;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.lcr--content {
    width: 45%;
    margin-right: 7rem;
    font-family: var(--primaryFont);
    font-style: normal;
}

.lcr--content h6 {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: -0.85rem;
    opacity: 0.8;
}

.lcr--content h1 {
    font-weight: 600;
    font-size: 3.25rem;
    line-height: 110%;
    margin: 1rem 0;
}

.lcr--content p {
    margin-top: 1.45rem;
    font-weight: 500;
    font-size: 1.15rem;
    opacity: 0.7;
}

.lcr-buttonContainer {
    margin-top: 2rem;
    width: 480px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "var(--primaryFont)", sans-serif;
}

@media (max-width: 1100px) {
    .landing--img {
        --img-size: 350px;
    }
    .lcl--content {
        margin: 2rem;
    }

    .lcr--content h6 {
        font-size: 1.1rem;
    }

    .lcr--content h1 {
        font-size: 3rem;
    }

    .lcr--content p {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 1rem;
    }

    .lcr-buttonContainer {
        margin-top: 1rem;
        flex-direction: column;
        width: 200px;
        height: 120px;
        align-items: flex-start;
    }
}

@media (max-width: 900px) {
    .landing--img {
        --img-size: 300px;
    }

    .landing--social {
        font-size: 30px;
    }
    .lcr--content {
        width: 60%;
        margin-top: 10%;
        margin-right: 6%;
    }

    .lcr-buttonContainer {
        margin-top: 1rem;
        flex-direction: column;
        width: 200px;
        height: 120px;
        align-items: flex-start;
    }
    .lcr-buttonContainer button:nth-child(2) {
        display: none;
    }
}

@media (max-width: 600px) {
    .landing--container {
        flex-direction: column;
        justify-content: space-between;
    }
    .landing--img {
        --img-size: 200px;
        left: 50%;
        top: 30%;
        border: 8px solid #eaeaea;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .landing--container-left {
        flex: initial;
        width: 100%;
        height: 30%;
    }
    .landing--social {
        display: none;
    }
    .landing--container-right {
        box-sizing: border-box;
        padding: 0 2rem;
        flex: initial;
        height: 70%;
        width: 100%;
        background-color: #eaeaea;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;
    }
    .lcr--content {
        margin-top: 20%;
        margin-right: initial;
        width: 100%;
    }
    .lcr--content h6 {
        font-size: 1rem;
        text-align: center;
    }
    .lcr--content h1 {
        font-size: 2.5rem;
        text-align: center;
    }
    .lcr--content p {
        font-size: 0.95rem;
        text-align: center;
        margin: 0 auto;
        width: 90%;
    }
    .lcr-buttonContainer {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .lcr-buttonContainer button {
        margin-top: -20%;
    }
}

@media (max-width: 500px) {
    .lcr--content {
        margin-top: 35%;
    }
}
@media (max-width: 370px) {
    .lcr--content {
        margin-top: 60%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .landing {
        height: 100%;
    }

    .landing--img {
        --img-size: 150px;
        left: 50%;
        top: 20%;
    }
}

.about {
    min-height: 100vh;
    position: relative;
}

.about .line-styling{
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 50px;
  width: 85%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about .line-styling .style-circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.about .line-styling .style-line{
  width: 95%;
  height: 5px;
  border-radius: 10px;
}

.about-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 5rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.about-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.5 1;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.about-description >h2 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.about-description > p {
    font-size: 1.25rem;
    color: #e0d9d9;
    font-family: var(--primaryFont);
}

.about-img {
    margin-top: 40px;
    pointer-events: none;
}

.about-img > img {
    width: 380px;
    flex: 0.5 1;
}

@media (min-width: 992px) and (max-width: 1380px) {

    .about-description > h2 {
      font-size: 3.3rem;
      margin-bottom: 30px;
    }
    .about-description > p {
      font-size: 1.15rem;
    }

    .about-img>img {
        width: 300px;
    } 
}

@media screen and (max-width: 992px){

  .about .line-styling{
    top: 20px;
  }

    .about-body {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }

    .about-description{
        flex: 0.6 1;
        box-sizing: border-box;
        /* padding: 5% 10%; */
        padding: 20px;

    }

    .about-description > h2 {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .about-description > p {
        font-size: 1.1rem;
    }

    .about-img > img {
        width: 250px;
        flex: 0.4 1;
    }

}

@media screen and (max-width: 800px){
  .about .line-styling .style-circle{
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .about-description > h2{
    align-self: center;
  }

}

@media screen and (max-width: 600px){

  .about .line-styling .style-circle{
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .about .line-styling .style-line{
    width: 93%;
    height: 3px;
    border-radius: 10px;
  }

  .about-description > h2 {
    font-size: 2.5rem;
  }

    .about-description > p{
      font-size: 0.95rem;
    }

    .about-img > img {
        width: 200px;
    }
}

.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 60vh;
    padding: 2.5rem 2rem 2rem 2rem;
}

.skillsHeader {
    display: flex;
    align-items: center;
    justify-content: center; 
}

.skillsHeader h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size: 3.5rem;
    text-align: center;
}

.skillsContainer {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 2rem;
}

.skill--scroll {
    width: 100%;
    margin: 0 2rem;
}


.skill--box {
    background: #FAFAFA;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 160px;
    height: 160px;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    transition: 300ms ease-in-out;
}

.skill--box:hover  {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}


.skill--box > img {
    height: 50px;
    pointer-events: none;
}


.skill--box h3 {
    font-family: Big Shoulders Text;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 1rem;
}


.marquee {
    padding: 3rem 0;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .skills {
        padding: 2rem 1rem 1rem 1rem;
    }
    .skillsContainer {
        padding: 1.5rem;
        margin-top: 1.5rem;
    }
}


@media screen and (max-width: 992px) {
    .skills {
        padding: 1rem;
        min-height: 100%;
    }
    .skillsContainer {
        padding: 1rem;
        margin: 1rem 0;
    }
    .skillsHeader h2 {
        font-size: 3.2rem;
    }
    .skill--box {
        width: 150px;
        height: 150px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 45px;
    }


    .skill--box h3 {
        font-size: 20px;
        margin-top: 1rem;
    }

}

@media screen and (max-width: 800px) {
    .skills {
        padding: 0.5rem;
    }
    .skillsContainer {
        padding: 0.5rem;
    }
    .skillsHeader h2 {
        font-size: 3rem;
    }

    .skill--scroll {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .skill--box {
        width: 135px;
        height: 135px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 40px;
    }

    .skillsHeader h2 {
        font-size: 2.5rem;
    }

    .skill--box h3 {
        font-size: 18px;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.education {
    min-height: 100vh;
}

.education-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: hsla(117, 56%, 49%, 0.702);
}

.education-description {
    width: 100%;
    flex: 0.65 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 7%;
}

.education-description > h1{
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.education-card {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.educard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.educard-img > img {
    width: 40px;
}

.education-details{
    margin-left: 0.6rem;
}

.education-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.education-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.education-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.education-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35 1;
    margin-top: 1rem;
    pointer-events: none;
}

.education-image > img {
    width: 100%;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .education {
        min-height: 100%;
    }
    .education-image {
        display: none;
    }
    .education-description {
        flex: 1 1;
        margin: auto;
    }    
    .education-description > h1{
        font-size: 3.2rem;
    }
    .education-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .education-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .education-description {
        padding: 1rem;
    }
    .education-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .educard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .educard-img > img {
        width: 30px;
    }
    .education-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .education-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .education-description > h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.experience{
    min-height: 100vh;
}

.experience-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.experience-description {
    width: 100%;
    flex: 0.65 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 2rem;
}

.experience-description > h1 {
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.experience-card {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.expcard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.expcard-img > img {
    width: 36px;
    pointer-events: none;
}

.experience-details {
    margin-left: 0.6rem;
}

.experience-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.experience-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.experience-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.experience-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35 1;
    margin-left: 7%;
    margin-top: 1rem;
    pointer-events: none;
}

.experience-image > img {
    width: 100%;
    pointer-events: none;
}






@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .experience {
        min-height: 100%;
    }
    .experience-image {
        display: none;
    }
    .experience-description {
        flex: 1 1;
        margin: auto;
    }    
    .experience-description > h1{
        font-size: 3.2rem;
    }
    .experience-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .experience-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .experience-description {
        padding: 1rem;
    }
    .experience-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .expcard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .expcard-img > img {
        width: 30px;
    }
    .experience-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .experience-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .experience-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .experience-description > h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.contacts {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}

.contacts--img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 280px;
    pointer-events: none;
}

.contacts--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 6rem 3rem;
    width: 80%;
    height: 100%;
    margin-top: 2rem;
}

.contacts--container > h1 {
    font-family: var(--primaryFont);
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
}

.contacts-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.contacts-form {
    display: flex;
    flex: 0.4 1;
    width: 100%;
}

.contacts-form form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
}

.input-container {
    width: 100%;
}

.form-input {
    height: 50px;
    box-sizing: border-box;
    border-radius: 50px;
    outline: none;
    margin-bottom: 2rem;
    padding: 0.7rem 0.7rem;
    border-radius: 20px;
    width: 100%;
    resize: none;
}

.form-message {
    height: 150px;
    box-sizing: border-box;
    border-radius: 50px;
    outline: none;
    margin-bottom: 2rem;
    padding: 0.7rem 0.7rem;
    border-radius: 20px;
    width: 100%;
    resize: none;
}

.submit-btn button {
    border: none;
    outline: none;
    width: 140px;
    height: 50px;
    border-radius: 50px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.submit-btn button p {
    font-size: 16px;
    font-family: var(--primaryFont);
}

.submit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.send-icon {
    font-size: 25px;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate(5px, -3px) rotate(-30deg);
            transform: translate(5px, -3px) rotate(-30deg);
}

.success-icon {
    font-size: 28px;
    transition: all 0.3s 0.8s ease-in-out;
}

.contacts-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.6 1;
    box-sizing: border-box;
    padding-left: 7rem;
    margin-top: 1.2rem;
}

.personal-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2.5rem;
}

.personal-details p {
    margin-left: 1.5rem;
    font-size: 18px;
    line-height: 110%;
    font-weight: 500;
    font-family: var(--primaryFont);
    width: 50%;
    word-break: break-word;
}

.socialmedia-icons {
    width: 38%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: 4rem;
}

@media (min-width: 992px) and (max-width: 1380px) {
    .contacts--img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 240px;
    }
    .contacts--container {
        padding: 2rem 4rem;
        width: 90%;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 992px) {
    .contacts--img {
        display: none;
    }
    .contacts--container {
        width: 100%;
        margin-top: 1rem;
    }
    .contacts--container > h1 {
        font-size: 3rem;
        margin: 0 0 1.2rem 0;
    }
    .contacts-details {
        padding-left: 4rem;
    }
    .personal-details p {
        margin-left: 1rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .contacts--container h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .contacts--container {
        padding: 2rem;
        margin-top: 0rem;
        align-items: center;
    }
    .contacts-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contacts-form {
        display: flex;
        flex: 1 1;
        width: 80%;
    }

    .form-input {
        margin-bottom: 0.45rem;
    }
    .contacts-form form {
        align-items: flex-end;
    }
    .contacts-details {
        padding-left: 0rem;
        padding: 1rem;
        align-items: center;
        margin-top: 2rem;
    }
    .socialmedia-icons {
        width: 100%;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        margin-top: 2rem;
    }
    .personal-details {
        margin-bottom: 1.8rem;
    }
    .personal-details p {
        margin-left: 1.2rem;
        width: 95%;
    }
}

@media screen and (max-width: 600px) {
    .contacts-form {
        display: flex;
        flex: 1 1;
        width: 100%;
    }
    .contacts--container h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 400px) {
    .contacts-details {
        padding-left: 0rem;
        padding: 0rem;
        align-items: center;
        margin-top: 2rem;
    }
    .submit-btn {
        align-self: center;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .contacts-details {
        padding-left: 0rem;
        padding: 0rem;
    }
}

@-webkit-keyframes spin {
    0% {
        opacity: 1;
        -webkit-transform: translate(5px, -3px) rotate(0deg);
                transform: translate(5px, -3px) rotate(0deg);
    }
    /* 60%{
      opacity: 0;
    }
    80%{
      opacity: 1;
    } */
    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        opacity: 1;
        -webkit-transform: translate(5px, -3px) rotate(0deg);
                transform: translate(5px, -3px) rotate(0deg);
    }
    /* 60%{
      opacity: 0;
    }
    80%{
      opacity: 1;
    } */
    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@-webkit-keyframes fly {
    /* 0%,100%{
        transform: translate(5px,-3px) rotate(-30deg);
        opacity: 1;
    }
    25%{
        transform: translate(15px,-10px) rotate(-30deg);
        opacity: 0.8;
    }
    50%{
        transform: translate(20px,-12px) rotate(-30deg);
        opacity: 0;
    }
    60%{
        transform: translate(-12px,10px) rotate(-30deg);
        opacity: 0;
    }
    75%{
        opacity: 0.8;
    } */
    10% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    20% {
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);
    }
    70% {
        -webkit-transform: translateX(60px);
                transform: translateX(60px);
    }
    100% {
        -webkit-transform: translateX(80px);
                transform: translateX(80px);
    }
}

@keyframes fly {
    /* 0%,100%{
        transform: translate(5px,-3px) rotate(-30deg);
        opacity: 1;
    }
    25%{
        transform: translate(15px,-10px) rotate(-30deg);
        opacity: 0.8;
    }
    50%{
        transform: translate(20px,-12px) rotate(-30deg);
        opacity: 0;
    }
    60%{
        transform: translate(-12px,10px) rotate(-30deg);
        opacity: 0;
    }
    75%{
        opacity: 0.8;
    } */
    10% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    20% {
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);
    }
    70% {
        -webkit-transform: translateX(60px);
                transform: translateX(60px);
    }
    100% {
        -webkit-transform: translateX(80px);
                transform: translateX(80px);
    }
}

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 2rem;
}


.projects--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}


.projects--header h1 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.projects--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projects--bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 4.5rem;
    gap: 4.5rem;
    width: 100%;
}

.projects--viewAll {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
}

.projects--viewAll a button {
    outline: none;
    border: none;
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: inherit;
    border-radius: 45px;
    font-size: 1.05rem;
    font-family: var(--primaryFont);
    font-weight: 500;
    padding-left: 1.5rem;
    cursor: pointer;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .projects--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .projects--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .projects--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .projects--viewAll {
        position: relative;
        margin-top: 4rem;
    }
    .projects--viewAll a {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);  
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.singleProject {
    box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
    width: 304px;
    height: 360px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.projectContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 1.525rem;
    line-height: 110%;
    text-align: center;
}

.projectContent > img {
    width: 100%;
    height: 60%;
    transition: opacity 0.7s 0.3s;
}

.singleProject:hover img{
    opacity: 0;
}

.project--showcaseBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.project--showcaseBtn > a {
    cursor: pointer;
    text-decoration: none;
    transition: -webkit-transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s, -webkit-transform 0.5s 0.3s;
}

.singleProject:hover .project--showcaseBtn a:nth-child(2) {
    -webkit-transform: translateX(-140px) scale(1.1) !important;
            transform: translateX(-140px) scale(1.1) !important;
}


.singleProject .project--desc{
    position: absolute;
    width: 95%;
    height: 160px;
    left: 0;
    top: 0;
    -webkit-transform: translate(-110%,40%);
            transform: translate(-110%,40%);
    padding: 15px;
    border-radius: 0 20px 20px 0;
    transition: -webkit-transform 0.9s;
    transition: transform 0.9s;
    transition: transform 0.9s, -webkit-transform 0.9s;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
}
  
.singleProject:hover .project--desc{
    -webkit-transform: translate(-2%,40%);
            transform: translate(-2%,40%);
}
  
.singleProject .project--lang{
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 140px;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s, -webkit-transform 0.5s 0.3s;
    padding: 0.825rem;
}
  
.singleProject:hover .project--lang{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.project--lang > span {
    font-family: var(--primaryFont);
    font-weight: 500;
    word-break: break-word; 
    line-height: 100%;
}
.backToTop {
    position: fixed; 
    right: 10px;
    bottom: 60px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        right: -10px;
        bottom: 50px;
        font-size: 2.75rem;
    }
}
.projectPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
}

.projectPage-header {
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.projectPage-header > h1 {
    font-size: 4rem;
    font-family: var(--primaryFont);
}

.projectPage-container {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.projectPage-search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-container {
    margin-top: 5rem;
    width: 100%;
}

.project-grid {
    display: flex;
    grid-gap: 4rem;
    gap: 4rem; 
}

@media (min-width: 992px) and (max-width: 1380px) {

}

@media screen and (max-width: 992px) {
    .projectPage-header {
        height: 25vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .project-grid {
        grid-gap: 3rem;
        gap: 3rem;
    }
}

@media screen and (max-width: 702px) {
    .project-grid {
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .projectPage-header h1 {
        font-size: 3rem;
    }
}
@media screen and (max-width: 400px) {
    .projectPage-header {
        height: 20vh;
    }
    .projectPage-header h1 {
        font-size: 2.5rem;
    }
}
:root {
    --primaryFont: 'Poppins', sans-serif;
    /* --primaryFont: 'Montserrat', sans-serif; */
    /* --primaryFont: 'Raleway', sans-serif; */
}


